import React, { useState,useEffect } from "react";
import SignOutButton from "./buttons/SignOutButton";
import { useIsAuthenticated } from "@azure/msal-react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import HelpIcon from "@mui/icons-material/Help";
import MenuItem from "@mui/material/MenuItem";
import { useMsal } from "@azure/msal-react";
import IconButton from "@mui/material/IconButton";
import StyledMenu from "./CustomMenu";
import { Link,useLocation } from "react-router-dom";

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState('');
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let location = useLocation();
  useEffect(() => {
    if(location.pathname!=="/help"){
      setTitle("Klimarelaterte Skadedata Webportal");
    }else{
      setTitle("Hjelp - Klimarelaterte Skadedata Webportal");
    }
  }, [location]);


  return (
    <div>
      <header
        className="header"
        style={{
          width: "100%",
          padding: "0px",
        }}
      >
        <div
          style={{
            width: "1378px",
            marginRight: "auto",
            marginLeft: "auto",
            textAlign: "left",
            fontSize: "20px",
          }}
        >
          <div
            style={{
              textAlign: "left",
              padding: "34px 0px 20px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
            <img
              alt="application logo"
              style={{ width: "250px" }}
              data-test="appLogo"
              src="https://www.finansnorge.no/siteassets/finans-norge-logo.svg"
            />
            </Link>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "15px",
                  alignItems: "center",
                  padding: "6px 8px",
                }}
              >
                <AccountCircleOutlinedIcon
                  style={{
                    marginRight: "8px",
                    width: "26.7px",
                    height: "26.7px",
                  }}
                />
                <span
                  style={{
                    fontSize: "18px",
                    fontFamily: "'Open sans'",
                    color: "#051D2E",
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {accounts[0].name}
                </span>
              </div>

              <div>
                <IconButton
                  onClick={handleClick}
                  style={{
                    marginLeft: "16px",
                    width: "26.7px",
                    height: "26.7px",
                  }}
                >
                  <HelpIcon
                    style={{
                      color: Boolean(anchorEl) ? "#ffffff" : "#051D2E",
                      backgroundColor: Boolean(anchorEl)
                        ? "#00586F"
                        : "#ffffff",
                    }}
                  />
                </IconButton>

                <StyledMenu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Link to="/help" style={{
                    color:"black",
                    textDecoration:"none"
                    
                  }}>
                    <MenuItem onClick={handleClose}>Hjelp</MenuItem>
                  </Link>
                </StyledMenu>
              </div>

              {isAuthenticated ? <SignOutButton data-test="signOut" /> : <></>}
            </div>
          </div>
          <p
            style={{
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Open sans'",
              color: "#051D2E",
              marginBottom: "20px",
            }}
          >
            Finans Norge - Klimarelaterte Skadedata Webportal
          </p>
          <h1
            style={{
              padding: "40px 0",
              fontSize: "34px",
              fontWeight: "bold",
              fontFamily: "'Merriweather'",
              color: "#051D2E",
              margin: "0",
            }}
            data-test="title"
          >
            {title}
          </h1>
        </div>
      </header>
    </div>
  );
}
