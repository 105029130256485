import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function FileList(props) {
  return (
    <>
      <ListGroup
        style={{ alignItems: "center", padding: "12px 40px 12px 40px" }}
      >
        {props.selectedFiles.map((file, index) => {
          return (
            <ListGroup.Item
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "56px",
                margin: "4px auto",
                border: "2px solid rgb(234, 235, 237)",
                padding: "20px 40px 20px 24px",
              }}
              key={index}
            >
              <span
                style={{
                  color: "#3370B1",
                  textDecoration: "underline",
                  fontWeight: "600",
                  fontFamily: "'Open sans'",
                  fontSize: "16px",
                }}
              >
                {file.name}
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    color: "#051D2E",
                    background: "#ffffff",
                    borderRadius: "0",
                    padding: "5px 10px",
                    borderColor: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onClick={() => props.onDelete(file)}
                >
                  <DeleteOutlineIcon />
                  <span
                    style={{
                      fontFamily: "Open sans",
                      color: "#051D2E",
                      fontSize: "16px",
                      fontWeight: "600",
                      marginLeft: "4px",
                    }}
                  >
                    Fjern
                  </span>
                </Button>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </>
  );
}
