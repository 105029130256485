import React, { useState, useRef, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import utils from "../../utils";
import FileList from "../FileList";
import ErrorComponent from "../ErrorMessage";
import CustomDatePicker from "../CustomDatePicker";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import styles from "./UploadFileButton.module.css";
import { FileDrop } from "react-file-drop";
import CustomSnackBar from "../CustomSnackBar";

export default function UploadFileButton(props) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hasFiles, setHasFiles] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState(true);
  const [loading, setloading] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setHasFiles(false);
    if (selectedFiles.length > 0) {
      setHasFiles(true);
    }
  }, [selectedFiles]);
  const inputFileRef = useRef(null);
  // When the upload button is click it triggers the onChange event of input type file.
  const onButtonClick = () => {
    inputFileRef.current.click();
  };

  const checkFileExt = (files) => {
    const validExts = [".xlsx", ".xls", ".csv", ".xml", ".json"];
    for (const file of files) {
      let fileExt = file.name;
      fileExt = fileExt.substring(fileExt.lastIndexOf("."));
      if (validExts.indexOf(fileExt) < 0) {
        alert(
          `Invalid file ${
            file.name
          } selected, valid files are of ${validExts.toString()} types`
        );
        return false;
      }
    }
    return true;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // Function to select files to be uploaded
  const handleSelectedFiles = (files) => {
    if (checkFileExt(files)) {
      setSelectedFiles((oldVal) => {
        let fileArray = [];
        fileArray = Object.values(files).filter(
          (item) => 
          !oldVal.some(ov=>ov.name === item.name)
          );
        return [...oldVal, ...fileArray];
      });
    }
  };

  // Function to deleted a file from the selected file list
  const deleteFile = (file) => {
    //To remove the file from the filelist on delete
    inputFileRef.current.value = "";
    setSelectedFiles((oldVal) => {
      let oldFileList = [...oldVal];
      let newFileList = [];
      newFileList = oldFileList.filter((item) => {
        return item !== file;
      });
      return [...newFileList];
    });
  };

  //Function to upload file to azure
  const handleUploading = async () => {
    setloading(true);
    try {
      const fileUploaded = await utils.azureCall(
        selectedFiles,
        props.email,
        props.companyCode
      );
      //const fileUploaded = true;
      setOpenSnackbar(true);
      if (fileUploaded) {
        setSelectedFiles([]);
        setSnackbarType(true);
        inputFileRef.current.value = "";
      } else {
        setSnackbarType(false);
      }
    } catch (error) {
      setHasError(true);
    }
    setloading(false);
  };

  return (
    <>
    <CustomSnackBar succeed={snackbarType} open={openSnackbar} handleClose={handleClose}/>
    <div className={styles["form-div"]}>
      <form className={styles["form"]}>
        <div className={styles["input-div"]}>
          <FileDrop
            onDrop={(files, event) => handleSelectedFiles(files)}
            className={styles["file-drop"]}
          >
            <div
              variant="default"
              className={styles["upload-button-container"]}
              id="uploadButton"
              data-test="uploadButton"
            >
              <CloudUploadOutlinedIcon style={{
                width:"32px",
                height:"32px",
                marginBottom:"4px"
              }}/>
              <div>
                <input
                  data-test="fileUploadInput"
                  type="file"
                  id="input"
                  multiple
                  hidden
                  ref={inputFileRef}
                  accept=" .json, .xml, .csv, .xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  onChange={(event) => handleSelectedFiles(event.target.files)}
                />
                <button
                  className={styles["velg-file-button"]}
                  onClick={onButtonClick}
                  type="button"
                >
                  Velg filer
                </button>{" "}
                <span className={styles["dra-text"]}>eller dra og slipp filer her</span>
              </div>
            </div>
          </FileDrop>
        </div>
        <div>
          {hasFiles ? (
            <FileList
              selectedFiles={selectedFiles}
              onDelete={deleteFile}
              data-test="FileListComponent"
            >
              {" "}
              {hasFiles}{" "}
            </FileList>
          ) : (
            <></>
          )}
        </div>

        <div>{hasError ? <ErrorComponent /> : <></>}</div>
        <div style={{
          display:"flex",
          alignItems:"end",
          justifyContent:"space-between",
          padding:"0px 40px",
          fontFamily:"Open sans"
        }}>
        <CustomDatePicker hasFiles={hasFiles} />
        <LoadingButton
          variant="contained"
          style={{
            fontFamily:"Open sans",
            fontSize:"18px",
            fontWeight:"normal",
            width:"200px",
            height:"41px",
            fontStyle:"normal",
            color: "#ffffff",
            background: "#00586F",
            opacity: hasFiles?loading?"0.5":"1":"0.5",
            padding:"8px 32px",
            borderRadius:"4px",
            textTransform: 'none',
            lineHeight:"24.51px"
            
          }}
          onClick={() => handleUploading()}
          disabled={!hasFiles}
          data-test="sendFile"
          loading={loading}
        >
          {loading?"Sender...":"Send"}
        </LoadingButton>
        </div>
      </form>
    </div>
    </>
  );
}
