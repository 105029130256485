import React from "react";
import styles from "./CustomSnackBar.module.css";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import IconButton from "@mui/material/IconButton";

export default function CustomSnackBar(props) {
  return (
    <div
      className={[
        styles.root,
        props.succeed ? styles.succeed : styles.fail,
      ].join(" ")}
      style={{
        display: !props.open ? "none" : "flex",
      }}
    >
      <div className={styles["message-container"]}>
        {props.succeed ? (
          <CheckCircleOutlinedIcon />
        ) : (
          <ErrorOutlineOutlinedIcon />
        )}
        <span className={styles.message}>
          {props.succeed ? "Filer har blitt sendt." : "Datafil ble ikke sendt."}
        </span>
      </div>
      <IconButton onClick={(e) => props.handleClose(e)}>
        <ClearOutlinedIcon />
      </IconButton>
    </div>
  );
}
