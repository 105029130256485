import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ProfileContent from "./components/ProfileContent";
import Login from "./components/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Help from "./components/Help";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AuthenticatedTemplate>
          <Header />
          <div className="flexWrapper">
            <Routes>
              <Route path="/" element={<ProfileContent />} />
              <Route path="help" element={<Help />} />
            </Routes>
            <Footer />
          </div>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </div>
    </BrowserRouter>
  );
}

export default App;
