import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import utils from "../utils";
import DatePicker from "@mui/lab/DesktopDatePicker";
import nbLocale from "date-fns/locale/nb";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";

export default function PeriodComponent(props) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  useEffect(() => {
    let dateObj = {
      startDate: "1900-01-01",
      endDate: utils.dateFormatter(new Date()),
    };
    if (startDate || endDate) {
      dateObj = {
        startDate: utils.dateFormatter(startDate),
        endDate: utils.dateFormatter(endDate),
      };
    }

    utils.sendPropertyFile(dateObj, "propertyFile");
  }, [startDate, endDate]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        paddingTop: "5px",
        marginTop: "8px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={nbLocale}>
        <DatePicker
          data-test="startDate"
          mask={"__.__.____"}
          label="Startdato"
          value={startDate}
          onChange={(date) => setStartDate(date)}
          renderInput={(params) => <TextField placeholder="dd/mm/yyyy" size="small" {...params} />}
        />
        <span style={{
            width:"24px"
        }}></span>
        <DatePicker
          data-test="endDate"
          mask={"__.__.____"}
          label="Sluttdato"
          value={endDate}
          onChange={(date) => setEndDate(date)}
          renderInput={(params) => <TextField size="small" {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}
