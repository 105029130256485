export const msalConfig = {
    auth: {
      clientId: "d7776bc7-5737-45e3-b102-a4f9881be8e4",
      authority: "https://login.microsoftonline.com/b7e22da2-2252-420d-930a-5119d917f62a", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.APPSETTING_REACT_APP_REDIRECT_URL,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: [
      "profile",
    ]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com"
  };