import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import PeriodComponent from "./PeriodComponent";

export default function CustomDatePicker(props) {
  return (
    <div>
      <div
        style={{
          fontFamily: "Open Sans",
          fontSize:"18px",
          color:"#3051D2E",
          width: "100%",
          display: "flex",
          paddingTop:"16px"
        }}
      >
        <span>Velg periode (valgfritt)</span>
      </div>
      <PeriodComponent />
    </div>
  );
}
