import React from "react";

export default function ErrorComponent(props) {
    return (
        <div style={{
            width: "80%",
            margin: "20px auto",
            background: "#FCC0C0",
            padding: "10px"
        }}>
        Error message here
        </div>
    );
}
