import React from "react";
import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";

const SignOutButton = () => {
    const handleLogout = () => {
    instance.logoutPopup().catch((e) => {
      console.error(e);
    });
  };

  const { instance } = useMsal();
  return (
    <Button
        data-test="SignOutButton"
        aria-label="SignOutButton"
        variant="default"
        style={{ 
            color: "#051C2E", 
            background: "#10B4BD",
            width:"127px",
            height:"45px",
            borderRadius: "0",
            padding: "10px 32px",
            marginLeft:"24px",
            fontFamily:"'Open sans'",
            fontSize:"'18px'"
        }}
        className="ml-auto"
        onClick={handleLogout}
    >
      Logg ut
    </Button>
  );
}
export default SignOutButton;