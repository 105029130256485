import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
        <div style= {{
                backgroundColor: "#eaebed",
                width: "1378px",
                marginRight: "auto",
                marginLeft: "auto",
                textAlign: "left",
                fontSize: "20px"
        }}>
            <div style= {{
                backgroundColor: "#eaebed",
                textAlign: "left",
                padding: "1.25em 1em"
            }}>
            <h2 style= {{fontSize: "1.25rem"}}>Personvern</h2>
                <a 
                    style={{color: "#616161"}}
                    data-test="link"
                    href="https://www.finansnorge.no/footer-innhold/personvernerklaring/">
                        Personvernerklæring
                </a>
            </div>
            <div style = {{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#005670",
                color: "#fff",

            }}>
                <div style={{
                    height: "50px",
                    lineHeight: "50px",
                    flexGrow: "1",
                    textAlign: "center"
                }}>
                    <b>Firmapost@finansnorge.no</b>
                </div>
                <div style={{
                    height: "50px",
                    lineHeight: "50px",
                    borderRight: "1px solid rgba(255,255,255,.3)",
                    borderLeft: "1px solid rgba(255, 255, 255, 0.3)",
                    flexGrow: "1",
                    textAlign: "center"
                }}>
                    <b>23 28 42 00</b>
                </div>
                <div style={{
                    height: "50px",
                    lineHeight: "50px",
                    flexGrow: "1",
                    textAlign: "center"
                }}>
                    <b>Hansteens gate 2, Oslo</b>
                </div>
            </div>
        </div> 
    </footer>
  );
}
