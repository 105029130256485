import { useMsal } from "@azure/msal-react";
import UploadFileButton from "./buttons/UploadFileButton";
import React, { useState, useEffect } from "react";
import utils from "../utils";
import axios from "axios";
import FileUploadOverview from "./FileUploadOverview";
import { loginRequest } from "../auth/authConfig.js";

export default function ProfileContent() {
  // Not showing logged in user's name for now.
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [companyCode, setCompanyCode] = useState('');

  const RequestAccessToken = async () =>  {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    let response;
    try{
      response = await instance.acquireTokenSilent(request);
    }catch(e){
      response =await instance.acquireTokenPopup(request);
    }

    const accessToken = response.accessToken;
    setAccessToken(accessToken);
    requestCompanyCode(accessToken);
  }

  const requestCompanyCode = async (accessToken) => {
    try{
      const response = await axios.get("https://graph.microsoft.com/V1.0/me/?$select=companyName", {
        headers: {
          Authorization: "Bearer " + accessToken, //the token is a variable which holds the token
        },
      });
      const companyCode = response.data.companyName;
      setCompanyCode(companyCode);
    }catch(e){
      console.log('requesting profile failed!')
    }
  };

  
  useEffect(() => {
    if (accounts[0]) {
      utils.warmUp();
    };
    RequestAccessToken();
  },[]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <UploadFileButton
        className="content"
        data-test="upload"
        email={accounts[0]?.username}
        companyCode={companyCode}
      />
      <FileUploadOverview 
        accessToken={accessToken}
        companyCode={companyCode}
      />
    </>
  );
}
