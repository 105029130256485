import { BlobServiceClient } from "@azure/storage-blob";
import axios from "axios";

const dateFormatter = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export function isInDesiredForm(str) {
  var n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n > 0;
}

let userSelectedDate = {
  startDate: "1900-01-01",
  endDate: dateFormatter(new Date()),
};

// TODO: unit test for this util helper
export const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return (h2>>>0).toString(16).padStart(8,0)+(h1>>>0).toString(16).padStart(8,0);
};

// extract the uploading codes to a function
const uploadToAzureBlobStorage = async(
  blobStorageClient,
  containerName,
  fileName,
  fileBuffer,
  companyCode
) => {
  const rawContainerClient =
    blobStorageClient.getContainerClient(containerName);
  const blockBlobClient = rawContainerClient.getBlockBlobClient(fileName);
  try {
    await blockBlobClient.uploadBrowserData(fileBuffer);
    if(containerName ==='properties'){
      const tags = {'companyCode':companyCode?companyCode:''}
      await blockBlobClient.setTags(tags)
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const initializeAzure = async () => {
  const account = process.env.REACT_APP_STORAGE_ACCOUNT;
  const sasResponse = await axios.get(
    process.env.REACT_APP_INTERNAL_API_URL +
      `getSASToken`
  );
  const sas = sasResponse.data;
  return new BlobServiceClient(
    `https://${account}.blob.core.windows.net${sas}`
  );
};

const sendPropertyFile = async (propertyFile) => {
    userSelectedDate = propertyFile;
};

const azureCall = async(selectedFiles, email, companyCode) => {
    const blobStorageClient = await initializeAzure();
  //upload all selected files and property files
  for (const file of selectedFiles) {
    const dateNow = Date.now();
    const rawFileName = dateNow + "_" + file.name;
    const fileHashCode = cyrb53(rawFileName);
    const jsonFileName =
      rawFileName.substring(0, rawFileName.lastIndexOf(".")) + ".json";

    if (
      !await uploadToAzureBlobStorage(blobStorageClient, "raw", rawFileName, file,companyCode) ||
      !await uploadToAzureBlobStorage(
        blobStorageClient,
        "properties",
        jsonFileName,
        JSON.stringify({ 
          ...userSelectedDate,
          "squenceNumber":fileHashCode,
          "email":email,
          "companyCode":companyCode
        }),
        companyCode
      )
    ) {
      return false;
    }
  }
  return true;
};

const warmUp = async () => {
  const blobStorageClient = await initializeAzure();
  const dateNow = Date.now();
  uploadToAzureBlobStorage(blobStorageClient, "warmup", dateNow, '','')
}

const utils = {
    uploadToAzureBlobStorage,
    azureCall,
    dateFormatter,
    sendPropertyFile,
    userSelectedDate,
    warmUp
  };
  
  export default utils;