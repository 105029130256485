import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";
import { useEffect } from "react";

export default function Login() {
  const { instance } = useMsal();
  useEffect(() => {
    instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
      });
  });
  return (
    <>
      <p>
        Redirecting...
      </p>
    </>
  );
}
